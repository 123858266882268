<template>
  <footer class="footer">
    <div class="footer-content">
      <p>TakŻyli © 2024</p>

      <div class="footer-links">
        <a class="link" href="/regulamin">Regulamin</a>
        <a class="link" href="/polityka-prywatnosci">Polityka prywatności</a>
      </div>
    </div>

    <div class="google-privacy">
      <span>Ta strona jest chroniona przez reCAPTCHA i obowiązują </span>
      <a class="link" href="https://policies.google.com/privacy">Polityka prywatności</a> oraz
      <a class="link" href="https://policies.google.com/terms">Warunki korzystania z usługi</a> Google.
    </div>
  </footer>
</template>

<script setup>
</script>

<style scoped lang="scss">
.footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  font-size: 0.875rem;
  padding: 8px 16px;

  @media (min-width: 768px) {
    height: 80px;
    padding: 8px 0;
  }
}

.google-privacy {
  color: #6d6d6d;
  font-size: 0.75rem;
}

.footer-content {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  gap: 24px;
  color: #dedede;

  @media (min-width: 768px) {
    padding: 0 16px;
    height: 80px;
    justify-content: space-between;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media (min-width: 420px) {
    flex-direction: row;
    gap: 16px;
  }
}

.link {
  color: #A28546;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: color 0.3s ease, border 0.3s ease;

  &:hover {
    border-bottom: 1px solid #A28546;
  }
}
</style>
