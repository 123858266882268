<template>
  <div class="menu">
    <nav>
      <ul class="menu-links">
        <li>
          <button :class="{ active: route.path === '/' }" class="menu-link" @click="goToPage('home')">Strona Główna
          </button>
        </li>

        <li>
          <button :class="{ active: route.path === '/o-nas' }" class="menu-link" @click="goToPage('about-us')">O
            nas
          </button>
        </li>

        <li>
          <button class="menu-link" @click="goToCart()">
            Kup Profil Pamięci
          </button>
        </li>

        <li>
          <button
              :class="{ active: route.path === '/profil/55-02-03' }"
              class="menu-link"
              @click="goToPage('memorial-profile-sample')"
          >
            Przykładowy Profil
          </button>
        </li>

        <li>
          <button :class="{ active: route.path === '/kontakt' }" class="menu-link" @click="goToPage('contact')">
            Kontakt
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  props: {
    toggleMobileMenu: {
      type: Function,
      required: true
    },
  },
  setup() {
    const route = useRoute();
    return {route};
  },
  methods: {
    goToPage(page) {
      this.$router.push({name: page});
      this.toggleMobileMenu();
    },
    goToCart() {
      window.location.href = `https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl`;

      this.toggleMobileMenu();
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 16px 0 8px;
  gap: 8px;

  text-decoration: none;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }
}

.menu-link {
  padding: 12px 32px 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  font-size: 1.125rem;
  border-right: 4px solid transparent;
  color: #1C1B1B;
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;

  @media (min-width: 768px) {
    font-size: 1rem;
    padding: 12px 16px 8px;
    border-radius: 10px 10px 0 0;
    color: #000000;
  }

  &:hover {
    background-color: transparent;
    border-color: #c0c0c0;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #c0c0c0;
      border-color: transparent;
    }
  }

  &.active {
    border-color: #000000;
    color: #000000;

    @media (min-width: 768px) {
      background-color: #000000;
      border-color: transparent;
      color: #ffffff;
      cursor: default;
    }
  }
}
</style>
