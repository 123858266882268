<template>
  <v-card
      v-if="!imagePreview || !isPreview"
      class="drag-and-drop-field"
      @dragover.prevent
      @dragenter.prevent
      @drop.prevent="handleDrop"
  >
    <v-icon class="file-upload-person-icon">
      <img alt="" height="36" src="@/assets/images/person-outline-36x36.svg" width="36">
    </v-icon>

    <p class="drag-or-upload-message">Przeciagnij tutaj lub wybierz {{ isMultiple ? 'zdjęcia' : 'zdjęcie' }}</p>

    <div class="upload-file-trigger" @click="openFilePicker">
      <span>Wybierz {{ isMultiple ? 'zdjęcia' : 'zdjęcie' }}</span>
      <v-icon class="rounded-circle" large>mdi-plus</v-icon>
    </div>

    <p class="file-upload-validation-info">Maksymalny rozmiar pliku: 7 MB</p>

    <input
        ref="fileInput"
        :multiple="isMultiple"
        accept="image/png, image/jpeg"
        class="file-input"
        type="file"
        @change="handleFilesChange"
    >
  </v-card>

  <div v-if="isPreview && imagePreview" class="image-preview-wrapper">
    <div
        v-if="photoUrl || imagePreview"
        :class="{ 'rounded': isRounded }"
        class="image-preview"
    >
      <img :src="imagePreview || (apiUrl + photoUrl)" alt="" class="image" height="240" width="240"/>
    </div>

    <v-btn v-if="photoUrl || imagePreview" class="delete-button" icon="" @click="deleteImage">
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    photoUrl: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: true,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    isUploadError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['files-changed', 'image-deleted', 'upload-error'],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      files: [],
      imagePreview: null,
    };
  },
  watch: {
    isUploadError(value) {
      if (value) {
        this.imagePreview = null;
      }
    },
  },
  mounted() {
    this.imagePreview = this.photoUrl ? this.apiUrl + this.photoUrl : '';
  },
  methods: {
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    async handleFilesChange(event) {
      const files = event.target?.files || event.dataTransfer?.files;

      if (!files?.length) {
        return;
      }

      this.files = Array.from(files); // Convert FileList to Array

      try {
        // Emit files-changed event and wait for upload to complete
        await this.$emit('files-changed', { files: this.files });

        // Set the preview image source only after successful upload
        if (!this.isMultiple) {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imagePreview = event.target.result;
          };
          reader.readAsDataURL(files[0]);
        } else {
          this.imagePreview = null; // Reset preview for multiple files
        }

        // Assuming you have a way to determine if upload was successful
        this.uploadSuccessful = true; // Set this flag based on actual upload success

      } catch (error) {
        console.error('Error uploading image:', error);
        this.imagePreview = null; // Ensure preview is reset if upload fails
        this.uploadSuccessful = false; // Reset success flag on error
        this.$emit('upload-error'); // Emit an error event
      }
    },
    handleDrop(event) {
      event.preventDefault();
      this.handleFilesChange(event);
    },
    deleteImage() {
      this.$emit('image-deleted', { photoUrl: this.photoUrl });
      this.imagePreview = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border: 2px dashed rgba(#90A4AE, 0.17);
  transition: all 0.3s ease;
}

.v-card:hover {
  background-color: rgba(#DEEBFF, 0.3);
  border-color: rgba(#90A4AE, 0.05);
}

.file-upload-person-icon {
  width: 48px;
  height: 48px;
  padding: 6px;
  margin-bottom: 8px;
}

.image-preview {
  width: 100%;
  margin-bottom: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: relative;

  img {
    max-width: 100%;
  }

  .delete-button {
    position: absolute;
    right: 8px;
    bottom: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.image-preview-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .image-preview {
    position: relative;
  }

  .image {
    min-width: 150px;
    object-fit: cover;

    @media (min-width: 360px) {
      width: 100%;
      height: auto;
    }

    @media (min-width: 768px) {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
    }
  }

  .rounded {
    overflow: hidden;

    .image {
      border-radius: 50% !important;
      min-width: 150px;
      min-height: 150px;

      @media (min-width: 360px) {
        width: 225px;
        height: 225px;
      }

      @media (min-width: 768px) {
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .delete-button {
    position: absolute;
    right: 16px;
    bottom: 24px;
  }
}

.upload-file-trigger {
  height: 40px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#171717, .17);
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #03558a;
    color: white;
  }
}

.drag-and-drop-field {
  height: 280px;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 12px;
  margin-bottom: 24px;
}

.drag-or-upload-message {
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #171717;
}

.file-upload-validation-info {
  font-size: 0.875rem;
  line-height: 130%;
  color: #737373;
  letter-spacing: 0.1px;
}
</style>
